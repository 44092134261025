// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import BuildingPage from "views/Property/Building.js";
import Person from "@material-ui/icons/Person";
import SignIn from "./views/UserManagement/Signinpage";

// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: BuildingPage,
    layout: "/admin",
  },
  // {
  //   path: "/buildings",
  //   name: "Property View",
  //   icon: Dashboard,
  //   component: BuildingPage,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: Dashboard,
    component: SignIn,
    layout: "/auth",
  },
];

export default dashboardRoutes;
