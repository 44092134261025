import * as Cookies from "js-cookie";

export const setCookie = (access_token, refresh_token) => {
  Cookies.set("access_token", access_token, { expires: 1 }, { httpOnly: true });
  Cookies.set("access_token", access_token, { expires: 1 }, { httpOnly: true });
  Cookies.set(
    "refresh_token",
    refresh_token,
    { expires: 1 },
    { httpOnly: true }
  );
};

export const storeDetails = (user_role, email, name, ProfileStatus) => {
  localStorage.setItem("UserRole", user_role);
  localStorage.setItem("Name", name);
  localStorage.setItem("Email",email);
  localStorage.setItem("ProfileStatus", ProfileStatus);
};

export const readDetails = (key) => {
  return localStorage.getItem(key);
};
export const setUserName = async (username, userid) => {
  localStorage.setItem("username", username);
  localStorage.setItem("userid", userid);
};

export const removeuserdetails = () => {
  localStorage.removeItem("username");
  localStorage.removeItem("userid");
};

export const readCookie = () => {
  const access_token = Cookies.getJSON("refresh_token");
  if (access_token) {
    return true;
  } else {
    return false;
  }
};

export const readCookieByName = (cookieName) => {
  const cookie = Cookies.getJSON(cookieName);
  if (cookie) {
    return cookie;
  } else {
    return null;
  }
};

export const removeSession = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  localStorage.removeItem("UserRole");
  localStorage.removeItem("Name");
  localStorage.removeItem("Email");
  localStorage.removeItem("ProfileStatus");
};
