import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from "history";
import { createHashHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./routing/PrivateRoute";
import PublicRoute from "./routing/PublicRoute";

// core components
import Admin from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import { usePromiseTracker } from "react-promise-tracker";
import loading from "../src/assets/img/loading.gif";
import "./assets/css/style.css";
import useGeoLocation from "../src/Utils/useGeoLocation";
import axios from "axios";
import { withStyles } from "@material-ui/core";

const hist = createHashHistory();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div className="loading">
        <span className="spanImg">
          <img className="imgloading" src={loading} alt="Loading..." />
        </span>
      </div>
    )
  );
};

const HooksDemo = () => {
  const location = useGeoLocation();
  console.log(
    "location",
    location.loaded
      ? JSON.stringify(location)
      : "Location data not available yet."
  );
  return <></>;
};

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiFilledInput-root ": {
      backgroundColor: "#F5F5FA",
    },
    ".MuiFilledInput-root.Mui-focused ": {
      backgroundColor: "#F5F5FA !important",
    },
    ".MuiFilledInput-root:hover": {
      backgroundColor: "#E4E4FA",
    },

    ".Mui-disabled": {
      backgroundColor: "#3929EA80 !important",
      color: "#FFF !important",
    },
    ".MuiButton-contained.Mui-disabled": {
      backgroundColor: "#3929EA80 !important",
      color: "#FFF !important",
    },
    ".MuiInputBase-input.Mui-disabled": {
      backgroundColor: "#0000001F !important",
      color: "#000 !important",
    },
  },
})(() => null);

ReactDOM.render(
  <Router history={hist}>
    <HooksDemo />
    <LoadingIndicator />
    <GlobalCss />
    <Switch>
      <PublicRoute path="/auth" component={AuthLayout} />
      <PrivateRoute path="/admin" component={Admin} />
      <Redirect from="/" to="/auth/login" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
