


import React, { useEffect, useState } from "react";
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { buildings } from "Utils/ApiEndpoints";
import { callAPI } from "Utils/HttpUtil";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import {
  Tablecell,
  useStyles,
} from "assets/jss/material-dashboard-react/views/propertyData/tableStyle";


function Buildings(){
  //const classes = useStyles();
    //   const history = useHistory();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    building: [],
    loading: true,
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const apiSuccessCallback = (responseData) => {
    if (responseData.status === 200) {
      setData({
        building: responseData.data.responseData,
        loading: false,
      });
    } else {
      setData({
        building: null,
        loading: false,
      });
    }
  };
  const apiErrorCallback = (err) => {
    console.log(err);
  };
  useEffect(() => {
    callAPI("POST", buildings, {}, apiSuccessCallback, apiErrorCallback);
  }, []);
  
  const headCells = [
    { label: "House Id" },
    { label: "Name" },
    { label: "House Type" },
    { label: "Security Deposit" },
    
    { label: "Rent" },
    { label: "Maintenance" },
    { label: "Tenant Email" },
    { label: "Tenant Phone Number" },
    { label: "Status" },
    {label: "Agreement Start Date"},
    {label: "Agreement End Date"}
   ]
   const billHeadCells = [ 
   {label:"Common Area Bills"},
   {label:"Amount"},
   {label:"Date"},
   {label:"Status"}]

  
  return (
    <div>
      {data.building
                ? data.building.map((b,index) => {
                  return (
                    <div key={index}>
                    <GridContainer >
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}  aria-controls="panel1a-content" id="panel1a-header" >
                      <Typography>
                      <p> 
                           <b>Building ID : {b.name}</b> <br/> 
                          <b>Building Name : {b.buildingName}</b>
                      </p> 
                    </Typography> 
                      </AccordionSummary>
                     <AccordionDetails>                    
                        <GridItem xs={12} md={6}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                      {headCells.map((headCell, headerIndex) => (
                                        <Tablecell key={headerIndex} align="left" >
                                            {headCell.label}
                                        </Tablecell>
                                      ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                { b.zohoHousesDtoList
                                  ? b.zohoHousesDtoList
                                    .map((house, houseIndex) => 
                                    {
                                      return (

                                        <TableRow key={houseIndex}>
                                          <Tablecell align="left">{house.houseUID}</Tablecell>
                                          <Tablecell align="left">{house.name}</Tablecell>
                                          <Tablecell align="left">{house.houseType}</Tablecell>
                                          <Tablecell align="left">{house.securityDeposit}</Tablecell>
                                          <Tablecell align="left">{house.rent}</Tablecell>
                                          <Tablecell align="left">{house.maintenance}</Tablecell>
                                          <Tablecell align="left">{house.tenantEmail}</Tablecell>
                                          <Tablecell align="left">{house.tenantPhone}</Tablecell>
                                          <Tablecell align="left">{house.status}</Tablecell>
                                          <Tablecell align="left">{moment(house.agreementStartDate).format("DD-MM-YYYY")}</Tablecell>
                                          <Tablecell align="left">{moment(house.agreementRenewalDate).format("DD-MM-YYYY")}</Tablecell>
                                        </TableRow>
                                      );
                                    })
                                : null}
                                  
                                </TableBody>
                              </Table>
                            </GridItem>
                
                      </AccordionDetails>
                    </Accordion>  
                    <GridItem xs={12} md={6}>
                    <Table>
                      <TableHead >
                          <TableRow >
                              {billHeadCells.map((billHeadCells, headerIndex1) => (
                                <Tablecell align="left" key={headerIndex1}> 
                                    {billHeadCells.label} 
                                </Tablecell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { b.paymentDtoList
                            ? b.paymentDtoList
                              .map((bill, billIndex) => 
                              {
                                return (

                                  <TableRow key={billIndex}>
                                    <Tablecell align="left">{bill.paymentType}</Tablecell>
                                    <Tablecell align="left">{bill.amount}</Tablecell>
                                    <Tablecell align="left">{moment(bill.billingDate).format("DD-MM-YYYY")}</Tablecell>
                                    <Tablecell align="left">{bill.status}</Tablecell>
                                    </TableRow>
                                    );
                                    })
                                : null}
                          </TableBody>
                      </Table>
                      </GridItem>
                      </GridContainer>
                      </div>
                    );
                })
        : null  }
    </div>
  );
  
              
}
export default Buildings;
