import { makeStyles } from "@material-ui/core/styles";
import { container } from "../../material-kit-react.js";
import { primaryColor } from "../../material-dashboard-react.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: "#0082FF",
    "&:hover": {
      backgroundColor: "#3929EA",
    },
  },
  Grid: {
    marginTop: theme.spacing(10),
  },
  card: {
    background:
      "linear-gradient(180deg, rgba(0,130,255,1) 0%, rgba(57,41,234,1) 100%);",
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "10vh",
    color: "#F5F5FA",
    paddingBottom: "0px",
    backgroundColor: "#FFF",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF",
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  },
  form: {
    margin: "0",
  },
  // cardHeader: {
  //   width: "auto",
  //   textAlign: "center",
  //   marginLeft: "20px",
  //   marginRight: "20px",
  //   marginTop: "-40px",
  //   padding: "20px 0",
  //   marginBottom: "15px",
  //   backgroundColor: "#F5F5FA !important",
  // },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
  },
  inputIconsColor: {
    color: "#495057",
  },
  logo: {
    textAlign: "center",
    paddingTop:"20px"
  },
  alink: {
    float: "right",
  },
  para: {
    color: primaryColor[1],
    margin: 0,
    marginTop: "25px",
  },
  error: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
    },
  },
}));

export default useStyles;